import React from 'react'
import { graphql } from 'gatsby'
import Article, { VARIANT_OPENER, VARIANT_TEXT_INTENDED, VARIANT_IMAGE_TEXT } from '../modules/Article.js'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components2/Layout'
import SEO from '../components/SEO'

const Page = ({ data }) => {
  const postNode = {
    title: `Restauration - ${config.siteTitle}`,
  }

  const block1 = {
    leading: 5,
    laces: true,
    variant: VARIANT_OPENER,
    headline: `Restauration`,
    lead: `Die Restauration im Raumausstatter-Handwerk ist ein wenig bekannter Arbeitsbereich. In diesem werden die textilen Ausstattungen von Gebäuden, Möbeln, Wänden und Dekorationen fachgerecht wiederhergestellt. Die Restauration umfasst unter anderem Wandbespannungen, Polster und Bezüge oder auch Vorhänge.`,
    image: {
      alt: 'Placeholder',
      fluid: data.opener.childImageSharp.fluid
    },
  }
  
  const block2 = {
    dropcap: true,
    variant: VARIANT_TEXT_INTENDED,
    copytext: `
      <p>Als Restaurator im Raumausstatter-Handwerk stehen Bernhard Ziegler vertiefte Kenntnisse um die Materie und der Handwerkskunst seines Fachs zur Verfügung. Durch diese Fortbildung verfügt er über die notwendige fachliche Kompetenz, erforderliche Maßnahmen aufgrund vorgefundener Zustände an Objekten wie Polstermöbeln und Innenausstattungen zu bewerten und durchzuführen.</p>
      <p>Diese Fortbildung wäre ohne eine solide Ausbildung im Raumausstatter-Handwerk nicht vorstellbar. Der Restaurator im Raumausstatter-Handwerk ist immer Meister seines Faches und beherrscht die traditionellen Handwerkstechniken in Theorie und Praxis. Somit kann er Objekte in ihrem jeweiligen Zustand erkennen, zeitgeschichtlich einordnen und auf ihre Erhaltungswürdigkeit bewerten.</p>
    `,
  }
  
  const block3 = {
    variant: VARIANT_IMAGE_TEXT,
    copytext: `<p>Geben Sie ihre Familienerbstücke oder die selbst erstandenen Möbelstücke zur weiteren Wertschöpfung vertrauensvoll in unsere Hände. Wir verpflichten uns deren Potenzial in Gänze zu erhalten und zur Geltung zu bringen.</p>`,
    image: {
      alt: 'Placeholder',
      fluid: data.chair.childImageSharp.fluid
    },
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Restauration - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="restauration" customTitle />

      <div>
        <article>
          <Article {...block1}></Article>
          <Article {...block2}></Article>
          <Article {...block3}></Article>
        </article>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    opener: file(relativePath: { eq: "restauration-opener.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    chair: file(relativePath: { eq: "polstern-stuhl.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
